import "core-js/stable";


// IE Polyfill remove when we no  longer support IE
$(document).ready(function() {
  var edgeVersion = window.navigator.userAgent.match(/Edge\/(\d{2})\./);
  var edgePartialSupport = (edgeVersion) ? (parseInt(edgeVersion[1], 10) >= 16) : false;
  if ("objectFit" in document.documentElement.style === false || edgePartialSupport) {
    import(  /* webpackChunkName: "object-fit-images" */ "object-fit-images")
    .then((objectFitImages) => {
      objectFitImages.default();
    })
    .catch(e => {
      console.log("Object fit problem: ", e.message, e.stack);
    });
  }

  if (!window.fetch) {
    import( /* webpackChunkName: "whatwg-fetch" */ 'whatwg-fetch')
    .catch(e => {
      console.log("Could not load whatwg-fetch: ", e.message, e.stack);
    });
  }
});
